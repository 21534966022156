.PageContainer {
  display: flex;
  flex-direction: row;
}

.MessagingContainer {
  height: 100vh;
  min-width: 80vw;
  display: flex;
  flex-flow: row wrap;
}

.OptionsContainer {
  display: flex;
  flex-direction: column;
}

.TextFieldContainer {
  padding: 2rem;
}

.ChatContainer {
  height: 100%;
  min-width: 30vw;
  max-width: 30vw;
  overflow-y: scroll;
  border: 1px solid black;
  border-radius: 5px;
}

.Message {
  padding: 1em;
  border-bottom: 1px solid grey;
}

.MessageField {
  padding-bottom: 1rem;
}
